<template>
  <div class="patient-summary-overview">
    <section class="patient-summary-overview__resume -pxy_2">
      <patient-profile-summary
        :patient-id="profileInfo.user_id"
        :first-name="profileInfo.firstname"
        :last-name="profileInfo.lastname"
        :gender="profileInfo.gender"
        :picture="profileInfo.picture"
        :institution-name="profileInfo.institution_name"
        :age="age"
        :remaining-days-in-on-hold="profileInfo.remaining_days_in_on_hold"
        :patient-attention-level="profileInfo.member_attention_level"
        :attention-level-editable="attentionLevelEditable"
        :vip="profileInfo.is_vip_account"
        :high-risk="profileInfo.patient_is_high_risk"
        :current-state="currentState"
        :profile-url="`${clinicalPortalBaseUrl}/patients/${profileInfo.user_id}/board`"
        :has-pending-exclusion="profileInfo.has_pending_exclusion_requests"
        :unit="profileInfo.unit"
        :is-blossom-member="isBlossomMember"
        :is-gutenberg-flow-member="profileInfo.gutenberg_flag"
        :aso-name="eligibility.aso_name"
        v-on="$listeners"
      />
      <sword-button-wuk
        class="patient-summary-overview__close-button"
        theme="text"
        round
        size="small"
        icon="close"
        @click.native="$emit('close-side-bar')"
      />
    </section>
    <div class="patient-summary-overview__details">
      <section class="patient-summary-overview__navigation -py_2">
        <widget-link
          v-for="item in navigationMetadata"
          v-if="!item.hidden"
          :key="`navigation-${$t(item.label)}`"
          :label="item.label"
          :icon="item.icon"
          :href="item.route"
          :disabled="item.disabled"
          target="_blank"
          rel="noreferrer noopener"
        />
      </section>

      <section v-if="profileInfo.unit !== UNIT.MIND && !!clinicalInfo?.therapy_name" class="-pxy_2">
        <p class="t3">
          <span class="label">{{ $t(clinicalInfo.therapy_name) }}</span>
          <template v-if="!!clinicalInfo.side">
            <span class="dot-separator -mx_1"></span>
            <span>{{ $t(clinicalInfo.side) }}</span>
          </template>
        </p>
        <p class="-t_medium">{{ $t(clinicalInfo.condition) }}</p>
      </section>

      <section v-if="profileInfo.unit !== UNIT.MIND && (clinicalInfo?.kit || clinicalInfo?.kit_status)" class="-pxy_2 flex items-center">
        <div>
          <p class="t3 label">{{ $t("SIDEBAR.KIT") }}</p>
          <div class="patient-summary-overview__kit">
            <span v-if="profileInfo.unit === UNIT.BLOOM" class="t2 -t_medium">{{ $t(clinicalInfo.bloom_pod_version) }}</span>
            <span v-else-if="clinicalInfo?.kit" class="t2 -t_medium">{{ $t(clinicalInfo.kit) }}</span>
            <kit-status-pill v-if="clinicalInfo?.kit_status" class="-ml_1" :status="clinicalInfo.kit_status" />
          </div>
        </div>
        <all-member-kits v-if="kitHistory.length > 1" :items="kitHistory" />
      </section>

      <section v-if="profileInfo.unit !== UNIT.MIND" class="-pxy_2">
        <template v-if="profileInfo.unit === UNIT.BLOOM">
          <p class="-my_1">
            <span v-if="bloomProtocol">{{ $t(bloomProtocol.bloom_program_name) }}</span>
            <span v-else>{{ $t("SIDEBAR.NO_PRESCRIPTION") }}</span>
            <sword-tag-wuk
              v-if="clinicalInfo?.no_pod_sessions_allowed"
              class="-ml_1"
              size="small"
              color="blue"
              :text="$t('NO_POD_SESSION.AVAILABLE')"
            />
          </p>
        </template>
        <template v-else>
          <phase-info-cell
            v-if="protocol"
            :current-phase="protocol.currentPhase"
            :total-phases="protocol.totalPhases"
            :start-date="protocol.startDate"
            :end-date="protocol.endDate"
            :name="protocol.name"
            :outdated="protocol.outdated"
          />
          <p v-else class="-my_1">{{ $t("SIDEBAR.DEFAULT_PRESCRIPTION") }}</p>
        </template>
        <patient-reminders :items="reminders" />
        <patient-goals
          :items="goals"
          @copyToClipboard="(content) => copyContentToClipboard('goals', content)"
        />
      </section>

      <section class="-px_2 -pt_2 patient-summary-overview__personal-data">
        <ul class="patient-summary-overview__contacts">
          <li v-for="contact in contacts" :key="`${$t(contact.label)} contact`" class="-mb_1">
            <p class="-mb_0 t3 label">{{ $t(contact.label) }}</p>
            <div class="patient-summary-overview__contact">
              <div class="patient-summary-overview__contact_content">
                <p class="compact-text">{{ contact.content || "-" }}</p>

                <sword-tooltip
                  v-if="!!contact.content && !!contact.warningMessage"
                  class="-ml_1"
                  placement="top"
                  theme="black"
                >
                  <sword-icon-wuk name="warning"/>
                  <template #content>
                    <p class="-mxy_0">{{ contact.warningMessage }}</p>
                  </template>
                </sword-tooltip>
              </div>

              <div v-if="!!contact.content" class="inline-icons">
                <sword-tooltip
                  v-if="contact.showCallButton"
                  class="-ml_1"
                  placement="top"
                  theme="black"
                >
                  <sword-button-wuk
                    icon="phone"
                    theme="transparent"
                    round
                    :aria-label="$t('SIDEBAR.PHONE.START_CALL')"
                    @click.native="startCall(contact.content)"
                  />
                  <template #content>
                    <p class="-mxy_0">{{ $t("SIDEBAR.PHONE.START_CALL") }}</p>
                  </template>
                </sword-tooltip>

                <sword-tooltip
                  placement="top"
                  theme="black"
                >
                  <sword-button-wuk
                    icon="copy"
                    theme="transparent"
                    round
                    :aria-label="`click to copy ${$t(contact.label)} to clipboard.`"
                    @click.native="copyContentToClipboard('contact', contact.content)"
                  />
                  <template #content>
                    <p class="-mxy_0">{{ $t("COPY.CLIPBOARD") }}</p>
                  </template>
                </sword-tooltip>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <section v-if="profileInfo.owner?.name" class="-px_2 -pt_2 therapist-owner">
        <p class="-mb_0 t3 label">{{ $t("copy_2992") }}</p>
        <p class="therapist-owner__value">
          <span class="compact-text">{{ profileInfo.owner.name }}</span>
          <span v-if="profileInfo.owner?.id === therapistInfo?.id">({{ $t("COMMON.you") }})</span>
          <temporary-transfer-pill
            v-if="profileInfo.owner.temporary_transfer"
            :start-date="profileInfo.owner.temporary_transfer.start_date"
            :end-date="profileInfo.owner.temporary_transfer.end_date"
          />
        </p>
      </section>

      <section class="-px_2 -pt_2 therapist-owner">
        <p class="-mb_0 t3 label">
          {{ $t("INTAKE.PT") }}
        </p>
        <p class="therapist-owner__value">
          <span class="compact-text">{{ profileInfo.intake_pt?.name || "-" }}</span>
          <span v-if="profileInfo.intake_pt?.id === therapistInfo?.id">({{ $t("COMMON.you") }})</span>
        </p>
      </section>

      <section v-if="careCoordinators?.length" class="-px_2 -pt_2 -pb_2">
        <p class="-mb_1 t3 label">{{ $t("ASSIGNED_CC_KEY") }}</p>
        <li v-for="(cc, ccIndex) in careCoordinators" :key="ccIndex">
          {{ cc?.firstname }} {{ cc?.lastname }}
        </li>
      </section>

      <section class="-pxy_2">
        <template v-if="profileInfo.unit === UNIT.SWORD">
          <p class="-mb_0 t3 label">{{ $t('SIDEBAR.DT_VERSION') }}</p>
          <p class="-mb_2">{{ clinicalInfo.apk_version || '-' }}</p>
        </template>

        <p class="-mb_0 t3 label">{{ $t('SIDEBAR.MEMBER_APP_VERSION') }}</p>
        <p>{{ appVersion || '-' }}</p>
      </section>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

// Components
import PatientProfileSummary from './PatientProfileSummary.vue'
import WidgetLink from './components/WidgetLink.vue'
import PatientGoals from './components/PatientGoals.vue'
import PatientReminders from './components/PatientReminders.vue'
import AllMemberKits from './components/AllMemberKits.vue'

import SwordTooltip from '@ui-kit/components/tooltips/SwordTooltip.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'
import PhaseInfoCell from '@ui-kit/components/PhaseInfoCell.vue'
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue'
import KitStatusPill from '@ui-kit/components/micro/KitStatusPill.vue'
import TemporaryTransferPill from '@ui-kit/components/micro/TemporaryTransferPill.vue'
import SwordTagWuk from '@ui-kit/components/tag/Tag.vue'

import { PATIENT_UNIT as UNIT } from '@/scripts/configs/patient-units'

export default {
  name: 'PatientSummaryOverview',
  components: {
    AllMemberKits,
    SwordTooltip,
    PatientProfileSummary,
    PhaseInfoCell,
    SwordButtonWuk,
    WidgetLink,
    SwordIconWuk,
    PatientReminders,
    PatientGoals,
    KitStatusPill,
    TemporaryTransferPill,
    SwordTagWuk,
  },
  props: {
    profileInfo: {
      type: Object,
      default: () => {},
    },
    therapistInfo: {
      type: Object,
      default: () => {},
    },
    careCoordinators: {
      type: Array,
      default: () => [],
    },
    reminders: {
      type: Array,
      default: () => [],
    },
    goals: {
      type: Array,
      default: () => [],
    },
    attentionLevelEditable: Boolean,
    isBlossomMember: Boolean,
    clinicalInfo: {
      type: Object,
      default: () => {},
    },
    protocol: {
      type: Object,
      default: () => {},
    },
    appVersion: {
      type: String,
      default: '',
    },
    age: {
      type: Number,
    },
    currentState: {
      type: String,
      default: null,
    },
    clinicalPortalBaseUrl: {
      type: String,
      default: '',
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    bloomProtocol: {
      type: Object,
    },
    kitHistory: {
      type: Array,
      default: () => [],
    },
    eligibility: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close-side-bar', 'copy-to-clipboard', 'open-ui', 'change-attention-level', 'send-to-user'],
  setup(props, { emit }) {
    const checkIfUserIsLead = () => {
      const authUserID = props.therapistInfo.id
      const leadID = props.profileInfo.lead_id
      const ownerID = props.profileInfo.owner.id

      return authUserID === leadID && authUserID !== ownerID
    }

    const navigationMetadata = computed(() => {
      const { user_id: userId, unit } = props.profileInfo
      const getPrescriptionsRoute = () => {
        const basePath = `${props.clinicalPortalBaseUrl}/patients/${userId}`

        if (unit === UNIT.MOVE) {
          return `${basePath}/move/prescriptions`
        }

        if (unit === UNIT.BLOOM || unit === UNIT.MIND) {
          return `${props.clinicalPortalBaseUrl}/prescriptions/${userId}`
        }

        return `${basePath}${
          props.clinicalInfo.total_non_default_sessions ||
          props.profileInfo?.has_pending_prescription
            ? '/sessions'
            : '/treatment-path'
        }`
      }

      return [
        {
          label: 'COMMON.PRESCRIPTIONS',
          route: getPrescriptionsRoute(),
          icon: 'prescription',
          disabled: checkIfUserIsLead(),
        },
        {
          label: 'copy_2681',
          route: `${props.clinicalPortalBaseUrl}/therapy_results/${userId}/${
            unit === UNIT.BLOOM ? 'clinical-sessions' : 'sessions'
          }`,
          icon: 'prescription-results',
          disabled: checkIfUserIsLead(),
          hidden: unit === UNIT.MIND || unit === UNIT.MOVE,
        },
        {
          label: 'clinical_records',
          route: `${props.clinicalPortalBaseUrl}/assessments/${userId}/listing`,
          icon: 'forms',
          disabled: checkIfUserIsLead(),
        },
        {
          label: 'copy_2952',
          route: `${props.clinicalPortalBaseUrl}/patients/${userId}/profile`,
          icon: 'user',
          disabled: checkIfUserIsLead(),
        },
      ]
    })

    const copyContentToClipboard = (context, content) => {
      emit('copy-to-clipboard', { context, content })
    }

    const startCall = (phoneNumber) => {
      window.open(`zoomphonecall://${phoneNumber.replace(' ', '')}`)
    }

    return {
      UNIT,
      navigationMetadata,
      copyContentToClipboard,
      startCall,
    }
  },
}
</script>

<style lang="scss" scoped>
.patient-summary-overview {
  @include t-medium;

  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  background-color: $color-white;
  color: $context-color-1;

  display: flex;
  flex-direction: column;

  .inline-icons {
    display: flex;
  }

  &__details {
    overflow: auto;
  }

  &__kit {
    display: flex;
    align-items: center;

    > span {
      max-width: 9rem;
      line-height: 1;
    }

  }
  &__personal-data {
    overflow-x: clip;
  }

  section {
    &:not(:last-child) {
      border-bottom: 1px solid $context-color-6;
    }

    p {
      margin: 0;
    }

    > p {
      display: flex;
      align-items: center;
    }
  }

  &__close-button {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;

    ::v-deep {
      .sword-button-wuk.--small.round {
        margin: 0;
      }

      .sword-icon.svg-icon {
        height: 0.77rem !important;
        width: 0.77rem !important;
      }
    }
  }

  &__navigation {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  &__contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 2.5rem;

    &_content {
      display: flex;
      align-items: baseline;

      .tooltip {
        color: $negative-color;
      }
    }

    .info-tooltip-container {
      height: initial;
      display: inline-block;
      position: relative;

      ::v-deep {
        .info-content-box {
          color: $color-white;
          background-color: $context-color-1;
          box-shadow: none;
          bottom: calc(100% + 0.45rem);
          top: -3.5rem;
          left: 50%;
          min-width: min-content;
          transform: translateX(-50%);
          height: min-content;

          &.t:before {
            border-top-color: $context-color-1;
            left: calc(50% - 0.3rem);
          }
        }
      }
    }

    .sword-button-wuk.--medium.round {
      margin: 0;
      min-width: initial;
      border: none;

      ::v-deep {
        &.sword-icon.svg-icon {
          width: 1.25rem;
          height: 1.25rem;
          margin: auto;
          fill: $context-color-1;
        }
      }
    }
  }

  @media screen and (max-width: $max-smartphone-size) {
    width: 100%;
    border-radius: 0;
  }
}

.therapist-owner {
  &__value {
    min-height: 2.5rem;
    gap: 0.4rem;
  }
}

.label {
  color: $context-color-4;
  font-weight: $input-font-weight-wuk;
}
</style>
