<template>
  <sword-modal
    type="warning"
    :header="header"
    :body="body"
    :body2nd="body2nd"
    :body3rd="body3rd"
    @close="$emit('close')"
    @cancel="$emit('cancel')"
    @ok="$emit('ok')"
  />
</template>

<script>
import SwordModal from '@/components/modals/SwordModal.vue'

export default {
  name: 'SimpleConfirmationModal',
  components: {
    SwordModal,
  },
  props: {
    type: {
      type: String,
      default: 'warning',
    },
    header: {
      type: String,
      default: 'copy_3118',
      required: false,
    },
    body: {
      type: String,
      default: 'copy_3119',
      required: false,
    },
    body2nd: {
      type: String,
      required: false,
    },
    body3rd: {
      type: String,
      required: false,
    },
  },
}
</script>
