import { FEATURE_TOGGLE } from '@/scripts/app-configs/constants'

const externalLinkOptions = [
  {
    optGroupLabel: 'APPOINTMENT_LINK.RESCHEDULE_OB_CALL',
    options: [
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-ob-call-link',
        copyable: true,
      },
    ],
  },
  {
    optGroupLabel: 'APPOINTMENT_LINK.REASSESSMENT_CALL',
    options: [
      { optLabel: 'APPOINTMENT_LINK.SEND_TO_MEMBER', optValue: 'send-reassessment-link' },
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-reassessment-link',
        copyable: true,
      },
    ],
  },
  {
    optGroupLabel: 'APPOINTMENT_LINK.CHECK_IN_CALL',
    options: [
      { optLabel: 'APPOINTMENT_LINK.SEND_TO_MEMBER', optValue: 'send-check-in-link' },
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-check-in-link',
        copyable: true,
      },
    ],
  },
]

export const QUICK_ACTIONS_CONFIG = [
  {
    key: 'open-notes',
    icon: 'notes',
    tooltip: 'copy_2673',
  },
  {
    key: 'send-appointment-links',
    icon: 'external-link',
    tooltip: 'SEND_APPOINTMENT_LINK',
    options: externalLinkOptions,
  },
  {
    key: 'open-sword-desk',
    icon: 'support-help',
    tooltip: 'SWORD_DESK.BTN_CREATE_TICKET',
    disabled: !FEATURE_TOGGLE.SWORD_DESK_ENABLED,
  },
]
