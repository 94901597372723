export default {
  prefillTicket: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: false,
      mock: () => {
        return 'mocks/endpoints/sword-desk/prefillTicket.json'
      },
    },
    $$makeUrl(configs, programUuid) {
      return `therapist/v${configs.api.version}/smart-escalate/ticket?program_uuid=${programUuid}`
    },
  },
  uploadTicketFile: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: false,
      mock: () => {
        return 'mocks/endpoints/sword-desk/prefillTicket.json'
      },
    },
    $$makeUrl(configs) {
      return `/therapist/v${configs.api.version}/service-desk/tickets/pt/attachments`
    },
  },
  createTicket: {
    api: {
      url: 'default',
      version: 1,
    },
    $$method: 'post',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      forceMock: false,
      mock: () => {
        return 'mocks/endpoints/sword-desk/prefillTicket.json'
      },
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/service-desk/tickets/pt`
    },
  },
}
