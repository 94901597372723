import i18n from '@/scripts/app-configs/i18n-config'

interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds?: number;
}

export const pluralizeHourCopy = function (hours: number, fullString?: boolean) {
  const pluralizedCopy = (i18n.t(hours > 1 ? 'CALENDAR.HOURS' : 'CALENDAR.HOUR') as string).toLowerCase()

  if (!fullString) return pluralizedCopy

  return `${hours} ${pluralizedCopy}`
}

/**
 * Split duration object to readable string
 * @name durationObjectToString object to time string
 * @returns {string} Readable String based on split time object
 */

// TODO Add timeframe & configs support as needed
export const durationObjectToString = ({ days = 0, hours = 0, minutes = 0 }: Duration): string => {

  const daysLabel = days > 1 ? i18n.t('COMMON.DAYS') : i18n.t('COMMON.DAY')
  const hoursLabel = hours > 1 ? i18n.t('copy_401').toString().toLowerCase() : i18n.t('CALENDAR.HOUR').toString().toLowerCase()
  const minutesLabel = minutes > 1 ? i18n.t('copy_400').toString().toLowerCase() : i18n.t('COMMON.MINUTES').toString().toLowerCase()

  if (days > 0) {
    return `${days} ${daysLabel}`
  }

  if (hours > 0) {
    return `${hours} ${hoursLabel}`
  }

  if (minutes > 0) {
    return `${minutes} ${minutesLabel}`
  }

  return ''
}

interface Configs {
  showDays: boolean;
  showHours: boolean;
  showMinutes: boolean;
  showSeconds: boolean;
}

const defaultConfigs: Configs = {
  showDays: false,
  showHours: true,
  showMinutes: true,
  showSeconds: true,
}

export const secondsToDurationObject = (seconds: number, configs: Configs = defaultConfigs): Duration => {

  const { showDays, showHours, showMinutes, showSeconds } = Object.assign({}, defaultConfigs, configs)

  const days = Math.floor(seconds / (3600 * 24))

  seconds -= days * 3600 * 24

  let hrs = Math.floor(seconds / 3600)

  hrs += days && !showDays ? days * 24 : 0
  seconds -= hrs * 3600
  seconds += days && !showDays ? days * 3600 * 24 : 0

  let mnts = Math.floor(seconds / 60)

  mnts += hrs && !showHours ? hrs * 60 : 0
  seconds -= mnts * 60
  seconds += hrs && !showHours ? hrs * 3600 : 0

  seconds += mnts && !showMinutes ? mnts * 60 : 0

  if (!showSeconds) {
    mnts += Math.ceil(seconds / 60)
    if (mnts >= 60) {
      hrs++
      mnts = 0
    }
    seconds = 0
  }

  return {
    days,
    hours: hrs,
    minutes: mnts,
    seconds,
  }
}
