import { useEventBus } from '@vueuse/core'
import kebabToCamelCase from '@/utils/filters/formaters/kebab-to-camel'

const bus = useEventBus<string>('global-modals')

export type GlobalModalKeyType = 'notes' |
  'memberSidebar' |
  'chatPopup' |
  'swordDesk' |
  'recommendationsFeed' |
  'recommendationsReasons' |
  'notificationsFeed' |
  'onboardingProfileMissing'

export type GlobalModalActionType = 'open' | 'close' | 'toggle'

export type GlobalModalStateType = { show: boolean, payload: object, [key: string]: string | object | boolean }

type ListenerOptionsType = {
  strictTopic?: GlobalModalKeyType | GlobalModalKeyType[]
}

const GLOBAL_MODAL_HANDLERS = ['notes',
  'memberSidebar',
  'chatPopup',
  'swordDesk',
  'recommendationsFeed',
  'recommendationsReasons',
  'notificationsFeed',
  'onboardingProfileMissing']

// Middleware to filter by desired modal types
function onGlobalModalTrigger(onEvent?: CallableFunction, options?: ListenerOptionsType) {
  bus.on((evTopic, evPayload) => {
    if (options?.strictTopic && options.strictTopic !== evTopic && !options.strictTopic.includes(evTopic as GlobalModalKeyType)) {
      return
    }

    if (!onEvent) return

    onEvent(evTopic, evPayload.action, evPayload.payload)
  })
}

/*
 *  Common base methods
 */

function openModal(modalKey: GlobalModalKeyType, payload?: object) {
  bus.emit(modalKey, { action: 'open', payload })
}

function closeModal(modalKey: GlobalModalKeyType, payload?: object) {
  bus.emit(modalKey, { action: 'close', payload })
}

function toggleModal(modalKey: GlobalModalKeyType, payload?: object) {
  bus.emit(modalKey, { action: 'toggle', payload })
}

// Generating base methods for all supported global modals - (openNotes, closeMemberSidebar, toggleMemberSidebar ...)
const modalsSpecificMethods: Record<string, CallableFunction> = GLOBAL_MODAL_HANDLERS.reduce((acc, modalHandler) => {
  return {
    ...acc,
    [kebabToCamelCase(`open-${modalHandler}`)]: (payload?: object) => openModal(modalHandler as GlobalModalKeyType, payload),
    [kebabToCamelCase(`close-${modalHandler}`)]: () => closeModal(modalHandler as GlobalModalKeyType),
    [kebabToCamelCase(`toggle-${modalHandler}`)]: (payload?: object) => toggleModal(modalHandler as GlobalModalKeyType, payload),
  }
}, {})

// Closes all global modals - Alias for triggering all close methods at once
const closeAll = () => {
  Object.keys(modalsSpecificMethods).forEach((fnHandler) => {
    if ((/^close[A-Z]/).test(fnHandler)) {
      modalsSpecificMethods[fnHandler]()
    }
  })
}

// Utility function to help with modal state management
const modalStateUtil = GLOBAL_MODAL_HANDLERS.reduce((acc, modalHandler) => {
  return { ...acc, [modalHandler]: { show: false, payload: {} } }
}, {})

const updateModalStateUtil = (currentState: GlobalModalStateType, action: GlobalModalActionType, payload: object) => {
  switch (action) {
    case 'open':
      return { show: true, payload }
    case 'close':
      return { show: false, payload: {} }
    case 'toggle':
      const shouldClose = currentState.show

      return { show: !shouldClose, payload: shouldClose ? {} : payload }
    default:
      return { show: false, payload: {} }
  }
}

const supportedMethods = {
  ...modalsSpecificMethods,
  closeAll,
  onGlobalModalTrigger,
  // Clear all onGlobalModalTrigger
  reset: bus.reset,
  modalStateUtil,
  updateModalStateUtil,
}

export const events = {
  onGlobalModalTrigger,
  reset: bus.reset,
}

export const triggers = {
  ...modalsSpecificMethods,
  closeAll,
}

export const utils = {
  modalStateUtil,
  updateModalStateUtil,
}
export default { ...supportedMethods }
