import { type Ref, ref, watch } from 'vue'
import lottie from 'lottie-web'

type useSwordLottieOptionsType = {
  loop?: boolean,
  autoplay?: boolean,
  segments?: [number | string, number | string], // Strings may be used for markers names, even if numeric. Number may be used has frames targets
}

type LottieMarkerType = {
  payload: string | number
  time: number
}
type LottieDataType = JSON & {
  markers: LottieMarkerType[]
}

const defaultOpts: useSwordLottieOptionsType = {
  loop: true,
  autoplay: true,
  segments: undefined,
}

export default function useLottie(lottieContainer: Ref, lottieData: LottieDataType, opts?: useSwordLottieOptionsType) {

  const options: useSwordLottieOptionsType = {
    ...defaultOpts,
    ...(opts || {}),
  }

  const animation = ref()
  const loopSegmentsInterval = ref()

  const renderLottie = () => {
    animation.value = lottie.loadAnimation({
      container: lottieContainer.value,
      renderer: 'svg',
      loop: options.loop,
      autoplay: options.autoplay,
      animationData: lottieData,
    })

    // Start the animation from startFrame to endFrame
    if (options.segments) loopBetweenFrames(options.segments[0], options.segments[1])
  }

  const loopBetweenFrames = (segmentStart: number | string, segmentEnd: number | string) => {

    const findMarkerFrame = (markerName: string | number) => animation.value.markers.find(({ payload }: LottieMarkerType) => {
      return typeof payload === 'string' ? String(markerName) === String(payload) : Number(markerName) === Number(payload)
    }).time

    // Strings may be used for markers names, even if numeric. Number may be used has frames targets
    const startFrame = typeof segmentStart === 'string' ? findMarkerFrame(String(segmentStart)) : segmentStart
    const endFrame = typeof segmentStart === 'string' ? findMarkerFrame(String(segmentEnd)) : segmentEnd

    animation.value.goToAndPlay(startFrame, true)

    // Check periodically if we need to reset to start frame
    loopSegmentsInterval.value = setInterval(() => {
      const currentFrame = animation.value.currentFrame

      if (currentFrame >= endFrame) {
        animation.value.goToAndPlay(startFrame, true) // Reset and start from start frame
      }
    }, 50) // Adjust for smoother transitions
  }

  // Giving support to any v-if conditions
  watch(lottieContainer, () => {
    if (!lottieContainer.value) clearInterval(loopSegmentsInterval.value)
    else renderLottie()
  }, { immediate: true })

  return {
    swordLottie: animation,
  }
}
