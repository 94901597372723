<template>
  <div>
    <collapsable-floating-panel
      v-if="modalOpen"
      ref="ticketPanelRef"
      :title="$t('SWORD_DESK.CREATE_TICKET')"
      class="global-modal-container"
      :ignore-click-outside-ui-actions="'open-sword-desk'"
      :collapse-on-click-outside="!showConfirmTicketDeletionModal"
      @close="showConfirmTicketDeletionModal = true"
    >
      <div v-if="isLoading" class="ai-loading -px_8">
        <div ref="lottieContainer" class="ai-loading__animation -mb_1"></div>
        <p class="ai-loading__text t3 -t_semi-bold center-text -pb_8">
          <template v-if="ticketBeingCreated"> {{ $t('SWORD_DESK.SUBMITTING_TICKET') }} </template>
          <template v-else> {{ $t('SWORD_DESK.CREATING_TICKET') }} </template>
        </p>
      </div>
      <template v-else>
        <sword-text-wuk
          v-model="ticketTitle"
          label="Title"
          label-as-placeholder
          fill-bg
          :validation="$v.ticketTitle"
        />

        <sword-textarea-wuk
          v-model="ticketDescription"
          class="-mt_2"
          :label="$t('copy_3462')"
          :placeholder="$t('copy_3462')"
          :rows="10"
          :validation="$v.ticketDescription"
          label-as-placeholder
          fill-bg
        />

        <sword-file-upload-wuk
          v-model="fileForUpload"
          placeholder="File upload"
          :validation="$v.fileForUpload"
          :help-text="$t('INPUTS.HELP_TEXTS.FILE_UPLOAD', { maxFiles: 1, maxFileSize: 50 })"
          fill-bg
          class="-mt_2 -mb_6"
        />
      </template>

      <sword-button-wuk
        v-if="!isLoading"
        class="sword-desk__submit-bt -my_3"
        text="COMMON.SUBMIT"
        theme="primary"
        size="medium"
        @click.native="submit"
      />
    </collapsable-floating-panel>

    <!-- Confirm ticket deletion -->
    <simple-confirmation-modal
      v-if="showConfirmTicketDeletionModal"
      header="SWORD_DESK.DELETE_TICKET"
      body=""
      @close="showConfirmTicketDeletionModal = false"
      @cancel="showConfirmTicketDeletionModal = false"
      @ok="closeAndDeleteTicket(); showConfirmTicketDeletionModal = false"
    />
  </div>
</template>

<script>
import Vue, { computed, ref, watch } from 'vue'
import router from '@/router'
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query'

// Components
import SimpleConfirmationModal from '@/components/modals/confirmation/SimpleConfirmationModal.vue'
import SwordTextWuk from '@ui-kit/components/inputs/SwordText.vue'
import SwordTextareaWuk from '@ui-kit/components/inputs/SwordTextarea.vue'
import SwordFileUploadWuk from '@ui-kit/components/inputs/SwordFileUpload.vue'
import CollapsableFloatingPanel from '@/components/sword-desk/CollapsableFloatingPanel.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

// Composables
import useLottie from '@/composables/use-lottie'

// Tools & assets
import { parseValidationsStr } from '@sword-health/input-validation'
import LokiSpeechAnimation from '@/assets/lotties/loki-speech-animation-v2-optimized.json'
import { events, triggers } from '@/scripts/global-modals-commands'
import i18n from '@/scripts/app-configs/i18n-config'

export default {
  name: 'SwordDesk',
  components: { SwordTextWuk, SwordTextareaWuk, SwordFileUploadWuk, CollapsableFloatingPanel, SwordButtonWuk, SimpleConfirmationModal },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const queryClient = useQueryClient()

    const vSetup = ref({})
    const ticketPanelRef = ref()
    const ticketTitle = ref('')
    const ticketDescription = ref('')
    const ticketUuid = ref('')
    const fileForUpload = ref(new File([], ''))
    const showConfirmTicketDeletionModal = ref(false)

    const isModalOpen = computed(() => props.modalOpen)
    const memberAccountUuid = computed(() => props.payload.memberAccountUuid)
    const memberProgramUuid = computed(() => props.payload.memberProgramUuid)
    const memberEmail = computed(() => props.payload.memberEmail)
    const isLoading = computed(() => aiGeneratingTicket.value || ticketBeingCreated.value)

    const lottieContainer = ref()

    useLottie(lottieContainer, LokiSpeechAnimation, { segments: ['9', '10'] })

    // Request ticket data so it gets prefilled
    const { data, isLoading: aiGeneratingTicket } = useQuery({
      queryKey: ['sword-desk-ticket-prefill', memberAccountUuid],
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: () => !!memberAccountUuid.value && !!isModalOpen.value,
      queryFn: async () => {
        const { data } = await Vue.$http('swordDesk/prefillTicket', memberProgramUuid.value)

        return data
      },
    })

    // Pre-upload file to get correspondent file token
    const {
      mutateAsync: updateFileAndGetToken,
    } = useMutation({
      retry: 3,
      mutationFn: async (file) => {
        const formData = new FormData()

        formData.append('attachment', file)

        const { data } = await Vue.$http('swordDesk/uploadTicketFile', null, { body: formData })

        return data
      },
    })

    // Submit ticket creation
    const {
      mutateAsync: submitTicket,
      isLoading: ticketBeingCreated,
    } = useMutation({
      mutationFn: async ({ body, file }) => {
        if (file && file.name && file.size) {
          const { file_token } = await updateFileAndGetToken(file)

          body.fileTokens.push(file_token)
        }

        return Vue.$http('swordDesk/createTicket', null, { body })
      },
      onSuccess: () => {
        Vue.$notify.success(i18n.t('SWORD_DESK.TICKET_SUBMITTED'), { timeout: 1500 })
      },
      onError: () => {
        Vue.$notify.error(i18n.t('copy_89'))
      },
    })

    const submit = async () => {

      vSetup.value.$touch()
      if (vSetup.value.$invalid) return

      const ticketRequestBody = {
        external_uuid: ticketUuid.value || undefined,
        subject: ticketTitle.value,
        description: ticketDescription.value,
        fileTokens: [],
        reporter: {
          account_uuid: memberAccountUuid.value,
          email: memberEmail.value,
        },
        properties: [
          {
            name: 'program',
            value: memberProgramUuid.value,
          },
          {
            name: 'tags',
            value: 'created_from_aifeed',
          },
        ],
      }

      await submitTicket({ body: ticketRequestBody, file: fileForUpload.value })
      closeAndDeleteTicket()
    }

    const closeAndDeleteTicket = () => {
      triggers.closeSwordDesk()
    }

    watch(isModalOpen, (open) => {
      if (open) return
      // Fully reset form
      ticketTitle.value = ''
      ticketDescription.value = ''
      ticketUuid.value = ''
      fileForUpload.value = new File([], '')
      queryClient.removeQueries({ queryKey: ['sword-desk-ticket-prefill'] })
      vSetup.value.$reset()
    })

    watch(data, (response) => {
      if (!response) return
      ticketTitle.value = response.title
      ticketDescription.value = response.description
      ticketUuid.value = response.ticket_uuid
      // Avoid required messages when AI fails on retrieving all the required data
      if (response.title) vSetup.value.ticketTitle.$touch()
      if (response.description) vSetup.value.ticketDescription.$touch()
    })

    // Detecting automatic ticket closing conditions and finner UX rules based on global modals activities
    events.onGlobalModalTrigger((modalKey, action, payload) => {
      if (!isModalOpen.value) return
      // Have an open ticket for a member, but open the member sidebar of another one
      if (modalKey === 'memberSidebar' && payload && payload?.memberUuid !== memberProgramUuid.value) triggers.closeSwordDesk()
      // Have an open ticket for a member and try to open it again for the same member (from anywhere)
      if (modalKey === 'swordDesk' && payload && payload?.memberProgramUuid === memberProgramUuid.value) {
        // In this case, we'll only un-collapse the ticket panel if it is collapsed. Otherwise, we do nothing
        if (ticketPanelRef.value.panelCollapsed.value) ticketPanelRef.value.togglePanelCollapsing()
      }
    }, { strictTopic: ['memberSidebar', 'swordDesk'] })

    // Detecting automatic ticket closing conditions based on route changes
    router.beforeEach(async (to, from, next) => {
      if (isModalOpen.value) triggers.closeSwordDesk()
      next()
    })

    return {
      lottieContainer,
      ticketPanelRef,
      isLoading,
      ticketBeingCreated,
      vSetup,
      ticketTitle,
      ticketDescription,
      fileForUpload,
      showConfirmTicketDeletionModal,
      closeAndDeleteTicket,
      submit,
    }
  },
  mounted() {
    this.vSetup = this.$v
  },
  validations() {
    return {
      ticketTitle: parseValidationsStr({
        required: true,
        maxL: 255,
      }),
      ticketDescription: parseValidationsStr({
        required: true,
      }),
      fileForUpload:  parseValidationsStr({
        maxFileSize: 50,
      }),
    }
  },
}

</script>

<style scoped lang="scss">
.ai-loading {
  max-height: 47vh;
  height: 44rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  &__animation {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
  }
}

.sword-desk__submit-bt {
  display: block;
  min-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.sword-modal-mask {
  ::v-deep .sword-modal-body {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
}
</style>
